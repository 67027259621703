html {
  font-family: 'Raleway';
}

.workbody {
  background-color: black;
  color: white;
}

.workbody1 {
  background-color: white;
  color: black;
}

nav {
  height: 6rem;
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logobox {
  color: white;
  text-decoration: none;
}

.logobox1 {
  color: black;
  text-decoration: none;
}


.hero {
  display: flex;
  width: 85%;
  margin: 0 auto;
}

.herotext {
  h2 {
    font-size: 250%;
    padding-top: 6rem;
  }

  p {
    font-size: 125%;
    padding-top: 3rem;
  }

  ul {
    list-style-type: none;
    padding-top: 3rem;

    li {
      font-size: 150%;
      padding: 0.5rem 0;
    }
  }
}

.headtwo {
  background: -webkit-linear-gradient(#F0F8FF,#E52B50);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}

.headtwo1 {
  background: -webkit-linear-gradient(#9F2B68,#AB274F);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
}



.buttons {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;

  a {
    padding: 0.5rem 1.5rem;
    border: 2px solid white;
    text-decoration: none;
    color: white;
    font-size: 150%;

    &:hover {
      color: black;
      background-color: white;
    }
  }

  a:first-of-type {
    margin-right: 2rem;
  }
}

.buttons1 {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;

  a {
    padding: 0.5rem 1.5rem;
    border: 2px solid black;
    text-decoration: none;
    color: black;
    font-size: 150%;

    &:hover {
      color: white;
      background-color: black;
    }
  }

  a:first-of-type {
    margin-right: 2rem;
  }
}

img {
  width: 20%;
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {
  .hero {
    width: 85%;
    margin: 0 auto;

    h2 {
      font-size: 250%;
    }
  }

  .clients {
    img {
      width: 30%;
    }
  }
}

@media screen and (max-width: 768px) {

    .aboutnav {
        margin: auto auto;
        justify-content: center;
        align-items: center;
    }

  }


@media screen and (max-width: 414px) {
  .navlist {
    width: 100vw;

    a {
      padding-left: 0;
    }
  }
}
