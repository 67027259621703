* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway';
}

.mainpage {
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        text-align: center;
        color: white;
        font-size: 500%;
        padding-bottom: 3rem;
    }
}

.mainpage2 {
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        text-align: center;
        color: black;
        font-size: 500%;
        padding-bottom: 3rem;
    }
}



.icons {
    color: white;
    position: fixed;
    top: 3rem;
    left: 3rem;
    cursor: pointer;
}

.icons2 {
    color: black;
    position: fixed;
    top: 3rem;
    left: 3rem;
    cursor: pointer;
}

.links {
    display: flex;
    justify-content: space-evenly;

    a {
        padding: 0.5rem;
        text-decoration: none;
        margin: 0 1rem;
        width: 10rem;
        text-align: center;
    }
}

.link1 {
    border-style: solid;
    border-image: linear-gradient(to right, #B0BF1A, #7CB9E8);
    -moz-border-image: -moz-linear-gradient(to right, #B0BF1A, #7CB9E8);
    -webkit-border-image: -webkit-linear-gradient(to right, #B0BF1A, #7CB9E8);
    border-image-slice: 1;
    -webkit-border-image-slice: 1;
    border-width: 3px;
    color: white;

    &:hover {
        background-color: white;
        color: black;
        border: 3px solid black;
    }
}

.link1a {
        border-style: solid;
        border-image: linear-gradient(to right, #0048BA, #9F2B68);
        -moz-border-image: -moz-linear-gradient(to right, #0048BA, #9F2B68);
        -webkit-border-image: -webkit-linear-gradient(to right, #0048BA, #9F2B68);
        border-image-slice: 1;
        -webkit-border-image-slice: 1;
        border-width: 3px;
        color: black;
    
        &:hover {
            background-color: black;
            color: white;
            border: 3px solid white;
        }
}

.link2 {
    border-style: solid;
    border-image: linear-gradient(to right, #7CB9E8, #C0E8D5);
    -moz-border-image: -moz-linear-gradient(to right, #7CB9E8, #C0E8D5);
    -webkit-border-image: -webkit-linear-gradient(to right, #7CB9E8, #C0E8D5);
    border-image-slice: 1;
    -webkit-border-image-slice: 1;
    border-width: 3px;
    color: white;

    &:hover {
        background-color: white;
        color: black;
        border: 3px solid black;
    }
}

.link2b {
    border-style: solid;
    border-image: linear-gradient(to right, #9F2B68, #AB274F);
    -moz-border-image: -moz-linear-gradient(to right, #9F2B68, #AB274F);
    -webkit-border-image: -webkit-linear-gradient(to right, #9F2B68, #AB274F);
    border-image-slice: 1;
    -webkit-border-image-slice: 1;
    border-width: 3px;
    color: black;

    &:hover {
        background-color: black;
        color: white;
        border: 3px solid white;
    }
}

.link3 {
    border-style: solid;
    border-image: linear-gradient(to right, #C0E8D5, #B284BE);
    -moz-border-image: -moz-linear-gradient(to right, #C0E8D5, #B284BE);
    -webkit-border-image: -webkit-linear-gradient(to right, #C0E8D5, #B284BE);
    border-image-slice: 1;
    -webkit-border-image-slice: 1;
    border-width: 3px;
    color: white;

    &:hover {
        background-color: white;
        color: black;
        border: 3px solid black;
    }
}

.link3b {
    border-style: solid;
    border-image: linear-gradient(to right, #AB274F, #D3212D);
    -moz-border-image: -moz-linear-gradient(to right, #AB274F, #D3212D);
    -webkit-border-image: -webkit-linear-gradient(to right, #AB274F, #D3212D);
    border-image-slice: 1;
    -webkit-border-image-slice: 1;
    border-width: 3px;
    color: black;

    &:hover {
        background-color: black;
        color: white;
        border: 3px solid white;
    }
}

.link4 {
    border-style: solid;
    border-image: linear-gradient(to right, #B284BE, #72A0C1);
    -moz-border-image: -moz-linear-gradient(to right, #B284BE, #72A0C1);
    -webkit-border-image: -webkit-linear-gradient(to right, #B284BE, #72A0C1);
    border-image-slice: 1;
    -webkit-border-image-slice: 1;
    border-width: 3px;
    color: white;

    &:hover {
        background-color: white;
        color: black;
        border: 3px solid black;
    }
}

.link4b {
    border-style: solid;
    border-image: linear-gradient(to right, #D3212D, #3B7A57);
    -moz-border-image: -moz-linear-gradient(to right, #D3212D, #3B7A57);
    -webkit-border-image: -webkit-linear-gradient(to right, #D3212D, #3B7A57);
    border-image-slice: 1;
    -webkit-border-image-slice: 1;
    border-width: 3px;
    color: black;

    &:hover {
        background-color: black;
        color: white;
        border: 3px solid white;
    }
}

@media screen and (max-width: 769px) {
    .mainpage, .mainpage2 {
        h1 {
            font-size: 400%;
        }
    }
}

@media screen and (max-width: 420px) {
    .mainpage, .mainpage2 {
        h1 {
            font-size: 200%;
        }
    }

    .links {
        flex-direction: column;
    }

    .link2, .link2b, .link3, .link3b, .link4, .link4b {
        margin-top: 1rem !important;
    }
}



