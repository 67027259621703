
.abouthead {
    background-color: black;
    color: white;
}

.abouthead1 {
    background-color: white;
    color: black;
}

nav {
    height: 6rem;
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logobox {
    color: white;
}

.logobox1 {
    color: black;
}

.abouttext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    color: #191919;
    width: 85%;
    margin: 0 auto;
    color: white;

    h2 {
        font-size: 250%;
        padding: 2rem 0;
    }

    p {
        font-size: 125%;
        padding: 1rem 0;
    }

    button {
        width: 25%;
        margin-top: 3rem;
        padding: 0.75rem 0;
        background-color: #edb3af;
        border: 2px solid #191919;
        border-radius: 5em;
    }
}

.abouttext1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    color: #191919;
    width: 85%;
    margin: 0 auto;
    color: black;

    h2 {
        font-size: 250%;
        padding: 2rem 0;
    }

    p {
        font-size: 125%;
        padding: 1rem 0;
    }

    button {
        width: 25%;
        margin-top: 3rem;
        padding: 0.75rem 0;
        background-color: #edb3af;
        border: 2px solid #191919;
        border-radius: 5em;
    }
}



.headone {
    background: -webkit-linear-gradient(#EDEAE0,#C46210);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.headone1 {
    background: -webkit-linear-gradient(#0048BA,#9F2B68);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
}

.bio {
    display: flex;
}

.edu {

    ul {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 5rem;

        li {
            margin: 1rem 1rem;
            width: 30%;
        }
    }
}
 
.resume {
    text-align: center;
    padding-bottom: 4rem;

    button {
        background-color: black;
        color: white;
        border: 2px solid white;
        padding: 0.75rem;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1024px) {

    .edu {

        ul {
            justify-content: space-between;
    
            li {
                width: 25%;
            }
        }
    }
}

@media screen and (max-width: 768px) {

    .aboutnav {
        margin: auto auto;
        justify-content: center;
        align-items: center;
    }

    .navlist {
        display: flex;
        width: 100vw;
        justify-content: space-evenly;
    
        a {
          padding-left: 0;
        }
      }
}

@media screen and (max-width: 415px) {
    .abouttext{
        padding-top: 3rem;
        width: 90%;
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
    }

    .abouttext1{
        padding-top: 3rem;
        width: 90%;
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
    }
    
    .edu {
        ul {
            flex-direction: column;
    
            li {
                width: 70%;
            }
        }
    }

    .resume {
        button {
            width: 60%;
        }
    }

}